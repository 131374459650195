<template>
    <section class="pa-4 formular">
        <v-container v-if="document_ready">

            <v-form @submit.prevent="submit" id="form" ref="form" class="formular">
                <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-1">

                    <!--MODIFIED DATE-->
                    <v-col cols="12">
                        <v-dialog
                            :disabled="!canEdit"
                            ref="picker_date"
                            v-model="picker_date"
                            :return-value.sync="deposit.modifiedDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled="!canEdit"
                                    v-model="deposit.modifiedDate"
                                    :label="$t('gold_deposit.dialog.modified_date')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    color="gold"
                                />
                            </template>
                            <v-date-picker
                                v-model="date"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="picker_date = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.picker_date.save(date);"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <v-select outlined dense color="gold"
                                  :disabled="!canEdit"
                                  :label="g_i18_keys('type')"
                                  item-text="alias"
                                  item-value="value"
                                  :items="select_type"
                                  v-model="deposit.type"
                                  hide-details
                        />
                    </v-col>
                    <v-col cols="12" v-if="!this.edit">
                        <v-text-field outlined dense color="gold"
                                      :disabled="!canEdit"
                                      :label="g_i18_filter('counts')"
                                      v-model="amount"
                                      hide-details="auto"

                                      v-validate="'required|amount'"
                                      data-vv-name="deposit_amount"
                                      :error-messages="errors.collect('deposit_amount')"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined dense color="gold"
                                      :disabled="!canEdit"
                                      :label="g_i18_keys('serial_number')"
                                      v-model="deposit.serialNumber"
                                      hide-details
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-dialog class="product-report-dialog"
                                  v-if="canEdit"
                                  v-model="dialog_agreements"
                                  max-width="640" persistent scrollable
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="generate-report" block
                                       :color="!Object.entries(getSelectedAgreement).length ? 'grey':'gold'" depressed
                                       dark dense small
                                       v-bind="attrs"
                                       v-on="on"
                                >
                                    <template v-slot:default v-if="Object.entries(getSelectedAgreement).length">
                                        <v-icon left>mdi-file-outline</v-icon>
                                        <span>{{ getSelectedAgreement.id }}</span>
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-icon v-on="on" right @click.native.stop="clearAgreement"
                                                        style="position: absolute; right: 0;">mdi-close-circle
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('tooltip.gold_deposit_agreement_clear') }}</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:default v-else>
                                        <v-icon left>mdi-file-multiple-outline</v-icon>
                                        <span>{{ $t('gold_deposit.dialog.assign_agreement') }}</span>
                                    </template>

                                </v-btn>
                            </template>
                            <v-card>
                                <v-toolbar color="gold" dark dense tile style="z-index: 10; flex: unset;">
                                    <v-icon left @click="dialog_agreements = false">mdi-close</v-icon>
                                    <v-toolbar-title>{{ $t('gold_deposit.dialog.assign_agreement') }}</v-toolbar-title>
                                </v-toolbar>

                                <v-card-text class="px-0 gold-deposit-assign-agreement">
                                    <DataSelect :data_view_name="'gold_deposit_agreements_list_select'"/>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <div v-else>
                            <v-icon left>mdi-file-outline</v-icon>
                            <span>{{ getSelectedAgreement.id }}</span>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <v-select outlined dense color="gold"
                                  :disabled="!canEdit"
                                  :label="g_i18_keys('deposit_type')"
                                  item-text="alias"
                                  item-value="value"
                                  :items="select_deposit_type"
                                  v-model="deposit.depositType"
                                  hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea outlined dense color="gold"
                                    :disabled="!canEdit"
                                    :label="g_i18_keys('comment')"
                                    auto-grow
                                    v-model="deposit.comment"
                        />
                    </v-col>
                </v-row>
            </v-form>

            <!--EDIT-->
            <div v-if="canEdit">
                <v-row v-if="edit" align="center" no-gutters>
                    <v-col cols="6" align="left">
                        <v-btn dense depressed color="gold" dark @click="submit">{{ $t('common.save') }}</v-btn>
                    </v-col>
                    <v-col cols="6" align="right">
                        <rgg-confirm :confirm="delete_gold_item" type="delete"
                                     message="gold_deposit.dialog.delete_confirm_message"/>
                    </v-col>
                </v-row>
                <!--CREATE-->
                <v-row v-else align="center" no-gutters>
                    <v-col cols="12" align="center">
                        <v-btn dense depressed color="gold" dark @click="submit">{{ $t('common.add') }}</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </section>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import {createGoldDeposit, deleteGoldDeposit, updateGoldDeposit} from "@/api/gold_deposit";
import DataSelect from "@/components/DataSelect";
import Permissions from "@/services/auth/permissions";

export default {
    name: "GoldDepositDialog",
    components: {
        DataSelect
    },
    props: {
        edit: Boolean,
        type: String,
        id: undefined,
        card_item: undefined
    },
    data: () => ({
        document_ready: true,
        picker_date: false,
        date: '',
        deposit: {},
        amount: 1,

        select_type: [],
        select_deposit_type: [],

        dialog_agreements: false,
        selected_agreement: {}

    }),
    mixins: [AuthMixin],
    computed: {
        getSelectedAgreement() {
            return this.selected_agreement;
        },

        canEdit() {
            return this.checkPermission(Permissions.ADMINISTRATOR)
        }
    },
    created() {
        this.$validator.extend('amount', {
            validate: (value) => {
                if (value <= 0) {
                    return false;
                }
                return (/^[0-9]+$/g.test(value));
                //return true;
            }
        });

        let goldbrick = this.g_i18_filter('goldbrick');
        this.select_type = [
            {alias: this.g_i18_filter('bar_1g', goldbrick), value: 'BAR_1G'},
            {alias: this.g_i18_filter('bar_2g', goldbrick), value: 'BAR_2G'},
            {alias: this.g_i18_filter('bar_5g', goldbrick), value: 'BAR_5G'},
            {alias: this.g_i18_filter('bar_10g', goldbrick), value: 'BAR_10G'},
            {alias: this.g_i18_filter('bar_20g', goldbrick), value: 'BAR_20G'},
            {alias: this.g_i18_filter('bar_50g', goldbrick), value: 'BAR_50G'},
            {alias: this.g_i18_filter('bar_100g', goldbrick), value: 'BAR_100G'},
            {alias: this.g_i18_filter('bar_250g', goldbrick), value: 'BAR_250G'},
            {alias: this.g_i18_filter('bar_500g', goldbrick), value: 'BAR_500G'},
            {alias: this.g_i18_filter('bar_1000g', goldbrick), value: 'BAR_1000G'},
            {alias: this.g_i18_filter('coin_one_ounce'), value: 'COIN_ONE_OUNCE'},
            {alias: this.g_i18_filter('saving_coin_one_ounce'), value: 'SAVING_COIN_ONE_OUNCE'}
        ];
        this.select_deposit_type = [
            {alias: this.$t('gold_deposit.deposit_type.ORDERED'), value: 'ORDERED'},
            {alias: this.$t('gold_deposit.deposit_type.FREE'), value: 'FREE'},
            {alias: this.$t('gold_deposit.deposit_type.IN_DEPOSIT'), value: 'IN_DEPOSIT'},
            {alias: this.$t('gold_deposit.deposit_type.AT_CUSTOMER'), value: 'AT_CUSTOMER'},
            {alias: this.$t('gold_deposit.deposit_type.COMPANY'), value: 'COMPANY'},
        ];

        if (this.edit) {
            let title = this.$t('gold_deposit.dialog.storage_item');
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

            Object.assign(this.deposit, this.card_item.raw_data);

            if (this.deposit.agreementId !== '') {
                this.selected_agreement.id = this.deposit.agreementId;
            }

        } else {
            this.deposit.modifiedDate = this.g_getDate();
            this.deposit.type = this.select_type[12].value;
            this.deposit.depositType = this.select_deposit_type[0].value;
            this.deposit.serialNumber = '';
            this.deposit.agreementId = '';
            this.deposit.comment = '';
        }

    },
    methods: {
        submit() {
            let gold_deposit = {};

            Object.assign(gold_deposit, this.deposit);

            this.$root.$emit('overlay', true);
            if (this.edit) {
                gold_deposit.agreementId = this.selected_agreement.id !== '' ? this.selected_agreement.id : '';
                updateGoldDeposit(this.card_item.id, gold_deposit).then(() => {
                    this.$parent.$emit('action', {func: 'closeDialog', data: 'gold_deposit_updated'});
                }).catch(() => {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
            } else {
                this.$validator.validate('deposit_amount')
                    .then((result) => {
                        if (result) {
                            gold_deposit.amount = this.amount;
                            gold_deposit.agreementId = this.selected_agreement.id;
                            createGoldDeposit(gold_deposit).then(() => {
                                this.$parent.$emit('call', {func: 'closeDialog', data: 'gold_deposit_created'});
                            }).catch(() => {
                                this.$root.$emit('overlay', false);
                                this.$root.$emit('notification', {
                                    type: 'error',
                                    icon: 'info',
                                    msg: 'notification.server_error'
                                });
                            })
                        } else {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'error',
                                msg: 'notification.deposit_create_error'
                            });
                        }
                    })

            }
        },

        delete_gold_item() {
            this.$root.$emit('overlay', true);
            deleteGoldDeposit(this.card_item.id).then((response) => {

                if (response.data) {
                    this.$parent.$emit('action', {func: 'closeDialog', data: 'gold_deposit_deleted'});
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('close-confirm-dialog');
                    this.$root.$emit('notification', {
                        type: 'info',
                        icon: 'info',
                        msg: 'notification.gold_deposit_error'
                    });
                }

            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('close-confirm-dialog');
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        },

        clearAgreement() {
            this.selected_agreement = {};
        }
    },
    mounted() {
        this.$root.$on('item-deposit', (_item) => {
            this.selected_agreement.id = _item;
            this.dialog_agreements = false;
        })
    },
    destroyed() {
    }
}
</script>

<style>
.gold-deposit-assign-agreement .toolbar-filter div.col-2 {
    max-width: 8%;
}

.gold-deposit-assign-agreement .toolbar-filter div.col-8 {
    flex: unset !important;
    max-width: 84%;
}
</style>